import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import ContactButton from '../components/contact-button';
import EmeraldLessley from '../components/emerald';

import headshot from '../images/voice-studio-headshot.png';
import './voice-studio.css';

const VoiceStudio = () => (
    <Layout>
        <SEO title="Voice Studio" />
        <div className="voice-studio">
            <div className="title">
                <EmeraldLessley />
                <h3>Voice Studio</h3>
            </div>

            <p>In 2017, I completed my Doctorate of Musical Arts from the University of Washington in Seattle. Because of my research and experience, I have been an invited speaker at the Northwest Voice Conference, the Vocology in Practice Conference in London, the London-based BAST webinar series, and NATS Chat.</p>

            <p>While I am happy and comfortable teaching many types of singing voice lessons, my specialty areas are:
                <ul>
                    <li>Classical/Opera</li>
                    <li>Musical Theater</li>
                    <li>Modern/Contemporary Art Music</li>
                    <li>Voice for Transgender Singers</li>
                </ul>
            </p>

            <p>
            The foundational aspect of my teaching is caring about my students. My job is to use what I know to help my students learn to become the musicians, the artists, and the people that they want to be. My students can benefit from my knowledge and my experience as they grow as scholars and musicians.
            </p>

            <p>
            In order to help my students reach their goals, I consider details such as learning styles and the importance of engaging each student, as well as vocal anatomy and physiology. Every student has a different perspective and way of learning, and it is simple to accommodate that in a one-on-one voice lesson. Every student is different, so every lesson is different.  I find that it is important for me to utilize a wide range of methods catered to the needs of each individual student. I am a visual learner, and I realize that my approach tends toward that style, so I put extra effort into providing variety in order to engage different learning styles in my teaching. A constant in my studio is the level of clarity and organization.  My students will always know what we are talking about and where we are going with the subject, and they will have a clear idea of the arc of the lesson. 
            </p>

            <p>
            In order to reach every student, I feel it is necessary to consider diversity. Creating a supportive and inclusive learning environment is one of the most important aspects of having a successful classroom or studio. I take this very seriously and will do everything in my ability to empower my students.
            </p>

            <p>
            The core of my teaching philosophy truly is caring about my students. I want to have an inclusive classroom and studio because I care that my students feel supported and have an environment in which they can thrive. I want to have clarity in my teaching because it matters to me that my students understand the material. I want to encourage self-awareness because it is important to me that my students continue growing and learning in the time between classes. I want my students to see the relationship between art and life because I want them to be the best people that they can be. I teach because I care about people, and teaching is my way of making the world a better place.
            </p>

            <p>Please contact me for rates and availability.</p>

            <ContactButton text="Contact Me" />

            <img src={headshot} alt="Emerald Lessley"/>
        </div>
    </Layout>
)

export default VoiceStudio;
